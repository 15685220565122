// Licensed to the Apache Software Foundation (ASF) under one
// or more contributor license agreements.  See the NOTICE file
// distributed with this work for additional information
// regarding copyright ownership.  The ASF licenses this file
// to you under the Apache License, Version 2.0 (the
// "License"); you may not use this file except in compliance
// with the License.  You may obtain a copy of the License at
//
//   http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing,
// software distributed under the License is distributed on an
// "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
// KIND, either express or implied.  See the License for the
// specific language governing permissions and limitations
// under the License.

<template>
  <div class="form-layout">
    <a-spin :spinning="loading">
      <a-form
        v-ctrl-enter="handleSubmit"
        :ref="formRef"
        :model="form"
        :rules="rules"
        @finish="handleSubmit"
        layout="vertical">
        <a-form-item name="username" ref="username" :rules="[{ required: true }]">
          <template #label :title="apiParams.username.description">
            {{ $t('label.username') }}
            <a-tooltip>
              <template #title>
                {{ apiParams.username.description }}
              </template>
              <info-circle-outlined style="color: rgba(0,0,0,.45)" />
            </a-tooltip>
          </template>
          <a-input
            v-model:value="form.username"
            :placeholder="apiParams.username.description"
            v-focus="true" />
        </a-form-item>
        <a-form-item name="password" ref="password" :rules="[{ required: true }]">
          <template #label :title="apiParams.password.description">
            {{ $t('label.password') }}
            <a-tooltip>
              <template #title>
                {{ apiParams.password.description }}
              </template>
              <info-circle-outlined style="color: rgba(0,0,0,.45)" />
            </a-tooltip>
          </template>
          <a-input-password
            v-model:value="form.password"
            :placeholder="apiParams.password.description"
            v-focus="true" />
        </a-form-item>
        <a-form-item :spinning="domainLoading" name="domainid" ref="domainid" v-if="this.isAdminOrDomainAdmin()">
          <template #label :title="apiParams.domainid.description">
            {{ $t('label.domainid') }}
            <a-tooltip>
              <template #title>
                {{ apiParams.domainid.description }}
              </template>
              <info-circle-outlined style="color: rgba(0,0,0,.45)" />
            </a-tooltip>
          </template>
          <a-select
            style="width: 100%"
            showSearch
            optionFilterProp="label"
            :filterOption="(input, option) => {
              return option.children[0].children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }"
            @change="handleDomainChanged"
            v-focus="true"
            v-model:value="form.domainid">
            <a-select-option v-for="(domain, index) in domains" :value="domain.id" :key="index">
              {{ domain.path || domain.name || domain.description }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item :spinning="accountLoading" name="account" ref="account" v-if="this.isAdminOrDomainAdmin()">
          <template #label :title="apiParams.account.description">
            {{ $t('label.account') }}
            <a-tooltip>
              <template #title>
                {{ apiParams.account.description }}
              </template>
              <info-circle-outlined style="color: rgba(0,0,0,.45)" />
            </a-tooltip>
          </template>
          <a-select
            style="width: 100%"
            showSearch
            optionFilterProp="label"
            :filterOption="(input, option) => {
              return option.children[0].children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }"
            v-focus="true"
            v-model:value="form.account">
            <a-select-option v-for="(account, index) in accounts" :value="account.name" :key="index">
              {{ '[' + account.domainpath + '] ' + (account.name || account.description) }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <div :span="24" class="action-button">
          <a-button @click="closeAction">{{ $t('label.cancel') }}</a-button>
          <a-button :loading="loading" ref="submit" type="primary" @click="handleSubmit">{{ $t('label.ok') }}</a-button>
        </div>
      </a-form>
    </a-spin>
  </div>
</template>

<script>
import { ref, reactive, toRaw } from 'vue'
import { api } from '@/api'
import { mixinForm } from '@/utils/mixin'

export default {
  name: 'AddVpnUser',
  mixins: [mixinForm],
  props: {},
  data () {
    return {
      domains: [],
      accounts: [],
      domainLoading: false,
      accountLoading: false,
      selectedDomain: {},
      loading: false,
      isSubmitted: false,
      hiddenElement: null
    }
  },
  beforeCreate () {
    this.apiParams = this.$getApiParams('addVpnUser')
  },
  created () {
    this.initForm()
    this.domains = [
      {
        id: null,
        name: ''
      }
    ]
    this.fetchData()
  },
  methods: {
    initForm () {
      this.formRef = ref()
      this.form = reactive({})
      this.rules = reactive({
        name: [{ required: true, message: this.$t('message.error.name') }]
      })
    },
    fetchData () {
      if (this.isAdminOrDomainAdmin()) {
        this.fetchDomainData()
      }
    },
    isAdminOrDomainAdmin () {
      return ['Admin', 'DomainAdmin'].includes(this.$store.getters.userInfo.roletype)
    },
    isValidValueForKey (obj, key) {
      return key in obj && obj[key] != null
    },
    arrayHasItems (array) {
      return array !== null && array !== undefined && Array.isArray(array) && array.length > 0
    },
    isObjectEmpty (obj) {
      return !(obj !== null && obj !== undefined && Object.keys(obj).length > 0 && obj.constructor === Object)
    },
    fetchDomainData () {
      const params = {}
      this.domainLoading = true
      this.domains = []
      api('listDomains', params).then(json => {
        const listdomains = json.listdomainsresponse.domain
        this.domains = this.domains.concat(listdomains)
      }).finally(() => {
        this.domainLoading = false
        if (this.arrayHasItems(this.domains)) {
          this.form.domainid = ''
          this.handleDomainChanged(this.domains[0])
        }
      })
    },
    handleDomainChanged (domain) {
      this.selectedDomain = domain
      this.form.account = ''
      this.fetchAccountData()
    },
    fetchAccountData () {
      const params = { domainid: this.selectedDomain, listall: true }
      this.accountLoading = true
      this.accounts = []
      api('listAccounts', params).then(json => {
        const listaccounts = json.listaccountsresponse.account
        this.accounts = this.accounts.concat(listaccounts)
      }).finally(() => {
        this.accountLoading = false
      })
    },
    handleSubmit (e) {
      e.preventDefault()
      if (this.loading) return
      this.formRef.value.validate().then(() => {
        const formRaw = toRaw(this.form)
        const values = this.handleRemoveFields(formRaw)
        if (!values.domainid) values.domainid = undefined
        this.loading = true
        api('addVpnUser', values).then(response => {
          this.$pollJob({
            jobId: response.addvpnuserresponse.jobid,
            title: this.$t('label.adding.user'),
            successMethod: () => {
              this.$emit('close-action')
            }
          })
        }).catch(error => {
          this.$notifyError(error)
        }).finally(() => {
          this.$emit('refresh-data')
          this.loading = false
        })
      }).catch(error => {
        this.formRef.value.scrollToField(error.errorFields[0].name)
      })
    },
    closeAction () {
      this.$emit('close-action')
    }
  }
}
</script>

<style scoped lang="less">
  .form-layout {
    width: 80vw;

    @media (min-width: 600px) {
      width: 450px;
    }
  }
</style>
